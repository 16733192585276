<template>
  <div>
    <Panel :module="panel.module" :title="panel.title" :showFilter="panel.showFilter" :showSearch="panel.showSearch"
      :showButtons="panel.showButtons">
      <div slot="content-main">
        <div>
          <br />
          <b-tabs>
            <b-tab title="Tag">
              <Tag />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>

import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import Tag from './Tag.vue'

import { mapMutations } from "vuex";

export default {
  name: "ConfiguracaoPlannerView",
  components: { Panel, Tag },
  data() {
    return {
      panel: {
        module: "Planner",
        title: "Configuração",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
    };
  },
  mounted() {
    this.removeLoading(["panel"]);
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
  },
};
</script>
